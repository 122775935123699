import React from "react"

import Layout from "../components/Layout"
import SEO from "@/components/SEO"
import { StaticImage } from "gatsby-plugin-image"

type PageProps = {
  data: any
}

const ContactUs: React.FC<PageProps> = ({ data }) => (
  <Layout
    sectionOne={
      <>
        <StaticImage
          src="../../static/images/2018/03/cont.jpg"
          className="absolute inset-0 -z-[1]"
          alt="Contact Us"
        />
        <div className="container text-white pt-96 pb-4 flex flex-col items-center text-center">
          <h1 className="pb-2 md:pb-8 responsive-heading uppercase">
            Contact Us
          </h1>
          <div className="pt-8 flex justify-center">
            <img src="/images/down.png" alt="Down arrow" />
          </div>
        </div>
      </>
    }
  >
    <SEO title="Contact Us" />
    <div className="container py-14">
      <div className="flex justify-center">
        <div className="flex flex-col gap-4 max-w-[700px]">
          <h3 className="mb-4 text-3xl uppercase">
            The RBAI Foundation was
            launched in 2013 to <span className="text-primary">engage</span> past pupils, <span className="text-primary">promote</span> the Inst ethos and
            build a <span className="text-primary">sustainable fundraising</span> programme to resource the School.
          </h3>
          <p>
            Working closely with the BOIA and across the wider Inst community,
            the RBAI Foundation is the hub for communications with past pupils
            and friends of the School.
          </p>
          <p>
            To find out about alumni events, reconnect with old school
            friends or arrange a visit to the School, contact:
          </p>

          <img
            src="/images/2024/02/contact-photo.jpg"
            alt="Peter Anderson"
            className="d-block mx-auto max-w-full mb-3"
          />

          <div className="grid sm:grid-cols-3 mt-4">
            <div className="mb-5">
              <p className="text-center">
                <strong>Ben Lambert</strong>
                <br />
                RBAI Foundation Officer
                <br />
                E:{" "}
                <a
                  className="link underline hover:no-underline"
                  href="mailto:b.lambert@rbai.org.uk"
                >
                  b.lambert@rbai.org.uk
                </a>
                <br />
                T: 02890240461
              </p>
            </div>

            <div className="mb-5">
              <p className="text-center">
                <strong>Sharon Casement</strong>
                <br />
                RBAI Foundation Assistant
                <br />
                E:{" "}
                <a
                  className="link underline hover:no-underline"
                  href="mailto:s.casement@rbai.org.uk"
                >
                  s.casement@rbai.org.uk
                </a>
                <br />
                T: 02890240461
              </p>
            </div>

            <div className="mb-5">
              <p className="text-center">
                <strong>Peter Anderson</strong>
                <br />
                RBAI Foundation Director
                <br />
                E:{" "}
                <a
                  className="link underline hover:no-underline"
                  href="mailto:p.anderson@rbai.org.uk"
                >
                  p.anderson@rbai.org.uk
                </a>
                <br />
                T: 02890240461
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ContactUs
